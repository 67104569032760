<template>
  <div >
  <van-nav-bar
  title="出库管理"
  left-text=""
  left-arrow
  @click-left="onGoBack"
  />
  <van-cell-group class="group" border=true>
    <van-cell icon="records"  title="收货人" :value=strincomeuser is-link @click="onHandleRouter('/searchorderuser')"/>
  </van-cell-group>

  <van-cell-group class="group" border=true>
    <van-cell icon="label"  title="扫码录入"/>
    <!-- <van-grid :column-num="2"  gutter="5" icon-size="50" direction="horizontal" style="color:#1989fa" >
      <van-grid-item icon="scan" text="单个扫码" />
      <van-grid-item icon="qr" text="连续扫码" @click="scanCode"/>
     <van-grid-item icon="qr" text="扫码录入" @click="onHandleRouter('/scanCodePage')"/>
    </van-grid>-->
    <!--<mu-wx-scan @returndata='getgoodcode'></mu-wx-scan>-->
    <wx-scan @returndata='getgoodcode'></wx-scan>
  </van-cell-group>
  <van-collapse v-model="activeNames">
    <van-collapse-item icon="label" title="手动录入" name="1">
      <van-field
      v-model="hand_value"
      name="hand_value"
      placeholder="请手动输入20位追溯码！"
      :rules="[{ required: true, message: '请输追溯码！' }]"
      >
      <template #button>
        <van-button size="small" type="primary" @click="hand_add" >确认录入</van-button>
      </template>
      </van-field>
    </van-collapse-item>
  </van-collapse>
  <van-cell-group class="" border=true>
    <van-cell>
      <template #title>
        <span class="custom-title">已扫</span>
        <van-tag type="danger">{{codelist.length}}数量</van-tag>
      </template>
      <template #right-icon>
        <van-button  size="small" round type="primary" plain @click="cleardata">清空</van-button>
      </template>
    </van-cell>
    <van-cell v-for="(value,index) in codelist" :key="index" :title=value.proCode>
      <template #right-icon>
         <van-icon name="delete" class="" @click="onDelLcode(index)"/>
      </template>
    </van-cell>
  </van-cell-group>
  </div>
  <van-button :disabled='isdisable' :loading='isloading'  type="success" class='bottombutton' block @click="insertexwarerecord">确认发货</van-button>
</template>
<script>
import { Toast } from 'vant';
import WxScan from './WxScan.vue';
//import MuWxScan from './MuWxScan.vue';
import { useUserStore } from '@/store/user.ts'
import axios from 'axios'

axios.defaults.timeout = 60 * 1000; // 30
const userStore=useUserStore()
const BASE_URL = process.env.VUE_APP_API_PORT_BASE_URL
export default {
  components: {
    [Toast.name]: Toast,
    WxScan
  },

  data() {
    return {
      strincomeuser:'选择收货人',
      income_userId:null,
      income_username:'',
      income_userphone:'',
      isdisable:false,
      isloading:false,
      hand_value:'',
      codelist:[],
      qrc_list:[],
      activeNames: []
    };
  },
  mounted(){
    //获取收货人信息
    let is_weixn=this.is_weixn();
    if (!is_weixn){
      this.$toast('请注意，扫码发货请使用微信浏览器！');
    }
    this.get_incomeuser_info()
  },
  methods: {
    
    //获取收货人信息
    get_incomeuser_info(){
      console.log(this.income_username)
      var query=this.$route.query;
      this.income_userId=query.user_id
      this.income_username=query.user_name
      this.income_userphone=query.user_phone_num
      this.strincomeuser=this.income_username+'('+this.income_userphone+')'
      
      if(this.income_username==undefined){
        this.strincomeuser='选择收货人'
      }
    },

    //获取扫码信息
    async getgoodcode(value){
      
      if(this.income_username==undefined){
        this.$toast('收货人不能为空！');
        return
      }
      let vm=this.qrc_list.find(val=>val===value)
      if (typeof vm != 'undefined') {
        this.$toast('重复扫码！');
        return
      }

      /*if(value.indexOf("https://f2s.pub/") ==-1 && value.indexOf("http://w9f.cc/")==-1 ){
        this.$toast('码错误，请检查！');
        return
      }*/
      if(value.slice(0,16)!="https://f2s.pub/" && value.slice(0,14)!="http://w9f.cc/" ){
        this.$message.info('码错误，请检查！');
        return
      }

      this.qrc_list.push(value)
      let params = {data: {}}
      //params={"code_site":value}

      params={
        'codeSite':value
      }

      let tokens={'Authorization':userStore.userInfo.token}
      //let res= await axios.post(BASE_URL+"/code/queryCodeSite",params,{headers:tokens})
      let res= await axios.post(BASE_URL+"/code/queryCodeMessage",params,{headers:tokens})
      if (res.data.code==200){
        this.codelist.push({
          //'proId':res.data.data.product_id,
          'proId':res.data.data.productId,
          //'proCode':res.data.data.proc_code,
          'proCode':res.data.data.proCode,
          'proName':''
        })
      }else{
        var timestamp=new Date().getTime()
        var rand=Math.floor(Math.random()*9000+1000)
        let mid_code='110'+rand+timestamp
        let params = {data: {}}
        params={
          "proCode":mid_code,
          "codeSite":value
        }
        let res2=await axios.post(BASE_URL+"/code/queryCodeInfo",params,{headers:tokens})
        if (res2.data.code==200){
          this.codelist.push({
            'proId':'9',
            'proCode':mid_code,
            'proName':''
          })
        }else{
          this.$toast(res2.data.msg);
        }
      }
      console.log(this.codelist.length)
      //data: {proc_code: '10690151009868965002', product_id: '10', id: 10001}
    },

    //手动录入
    async hand_add(){
      let code_index=this.hand_value.slice(0,2);
      console.log(code_index)
      if (this.hand_value.length!=20){
        this.$toast('码位数不对，请查实');
        return
      }
      if(code_index!='10' && code_index!='11' && code_index!='12'){
        this.$toast('码格式不对，请查实');
        return
      }
      let vm=this.qrc_list.find(val=>val===this.hand_value)
      if (typeof vm != 'undefined') {
        this.$toast('重复输入！');
        return
      }
      //this.qrc_list.push(this.hand_value)
      let params = {data: {}}
      params={"proCode":this.hand_value}
      let tokens={'Authorization':userStore.userInfo.token}
      let res= await axios.post(BASE_URL+"/code/queryCode",params,{headers:tokens})
      if (res.data.code==200){
        this.codelist.push({
          'proId':res.data.data.productId,
          'proCode':this.hand_value,
          'proName':''
        })
        this.qrc_list.push(this.hand_value)
      }else{
        //this.$toast('无法输入，请检查码是否正确！')
        this.$toast(res.data.msg)
        return;
      }
      this.hand_value=''
    },

    is_weixn() {
      return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
    },
    //清除数据
    cleardata(){
      this.codelist=[]
      this.qrc_list=[]
    },
    
    //插入出货数据
    insertexwarerecord(){
      if(this.income_username==undefined){
        this.$toast('收货人不能为空！');
        return
      }
      if(this.codelist.length==0){
        this.$toast('货物不能为空！');
        return
      }
      this.isdisable=true
      this.isloading=true
      let params = {data: {}}

      params={
        codeArray:this.codelist,
        deliveryId:userStore.userInfo.id,
        conId:this.income_userId-0,
        conName: this.income_username,
        conPhoneNum:this.income_userphone
      }

      let tokens={'Authorization':userStore.userInfo.token}
      axios.post(BASE_URL+'/code/modifyCodeBelonger',params,{headers:tokens})
      .then(res=>{
        this.$toast(res.data.msg);
        //清除数据
        if(res.data.code==200){
          this.codelist=[]
          this.qrc_list=[]
        }
        this.isdisable=false
        this.isloading=false
      })
    },
    
    //删除信息
    onDelLcode(val){
      /*var index= this.codelist.findIndex(item =>{
        if(item==val){
          return true;
        }
      })*/
      //this.pid.splice(this.pid.indexOf(item.id),1)
      //this.codelist.splice(index,1)
      this.qrc_list.splice(val,1)
      this.codelist.splice(val,1)
    },
  },
};
</script>

<style lang="less">
.group{
  margin: 5px auto;
}
.goods {
  padding-bottom: 50px;
  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }

  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  &-tag {
    margin-left: 5px;
  }
}
.bottombutton{
  position: fixed;
  //position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
</style>
